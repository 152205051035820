// extracted by mini-css-extract-plugin
export var caseStudyVideoReview = "fj_l1";
export var caseStudyVideoReview__bgRings = "fj_KZ";
export var caseStudyVideoReview__clientImage = "fj_K1";
export var caseStudyVideoReview__container = "fj_KY";
export var caseStudyVideoReview__logo = "fj_Lb";
export var caseStudyVideoReview__name = "fj_Lc";
export var caseStudyVideoReview__playWrapper = "fj_KQ";
export var caseStudyVideoReview__playWrapper_videoReviewImpact = "fj_KS";
export var caseStudyVideoReview__playWrapper_videoReviewIx = "fj_KX";
export var caseStudyVideoReview__playWrapper_videoReviewPmk = "fj_KV";
export var caseStudyVideoReview__playWrapper_videoReviewRef = "fj_KW";
export var caseStudyVideoReview__playWrapper_videoReviewTradeaboat = "fj_KR";
export var caseStudyVideoReview__playWrapper_videoReviewTradervs = "fj_KT";
export var caseStudyVideoReview__text = "fj_Ld";
export var caseStudyVideoReview__title = "fj_K0";
export var caseStudyVideoReview__videoYoutube = "fj_K9";
export var caseStudyVideoReview__wrapper = "fj_K2";
export var caseStudyVideoReview__wrapper_videoReviewImpact = "fj_K5";
export var caseStudyVideoReview__wrapper_videoReviewIx = "fj_K8";
export var caseStudyVideoReview__wrapper_videoReviewPmk = "fj_K6";
export var caseStudyVideoReview__wrapper_videoReviewRef = "fj_K7";
export var caseStudyVideoReview__wrapper_videoReviewTradeaboat = "fj_K3";
export var caseStudyVideoReview__wrapper_videoReviewTradervs = "fj_K4";