// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bz_k3";
export var caseStudyBackground__lineColor = "bz_k0";
export var caseStudyHead = "bz_kW";
export var caseStudyHead__imageWrapper = "bz_kX";
export var caseStudyProjectsSection = "bz_k4";
export var caseStudyQuote__bgRing = "bz_kZ";
export var caseStudyQuote__bottomVideo = "bz_lZ";
export var caseStudyVideoReview = "bz_l1";
export var caseStudyVideoReview__bgRing = "bz_l2";
export var caseStudyVideo__ring = "bz_k2";
export var caseStudy__bgDark = "bz_kV";
export var caseStudy__bgLight = "bz_kT";
export var caseStudy__bgLightReverse = "bz_l0";